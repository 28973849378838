import request from '../util/request'

//返回待审批面试人员列表并判断面试官是否绑定sap
export function GetApprovalList(data) {
    return request({
        url: '/api/XmhHr/ReturnInterviewer',
        method: 'post',
        params : data
    })
}

//删除某条应聘人员列表
export function delInterviewer(data) {
    return request({
        url: '/api/XmhHr/RemoveInterviewData',
        method: 'post',
        data
    })
}

//公众号绑定sap账号
export function bindAccount(data) {
    return request({
        url: '/api/XmhHr/BindSAP',
        method: 'post',
        data : data
    })
}

//返回待审批面试人员信息
export function GetApprovalDetail(data) {
    return request({
        url: '/api/XmhHr/ReturnInterviewerInfo',
        method: 'post',
        data : data
    })
}

//面试官提交面试结果
export function SubmitResults(data) {
    return request({
        url: '/api/XmhHr/SubmitInterviewResult',
        method: 'post',
        data : data
    })
}
