import Vue from 'vue'
import { Button } from 'vant'
import { Icon } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Step, Steps } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Uploader } from 'vant';
import { Tab, Tabs } from 'vant';
import { Picker } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { DatetimePicker } from 'vant';
import { Divider } from 'vant';
import { Toast } from 'vant';
import { Notify } from 'vant';
import { Popup } from 'vant';
import { Area } from 'vant';
import { Empty } from 'vant';
import { NavBar } from 'vant';
import { RadioGroup, Radio } from 'vant';

Vue.use(Button)
Vue.use(Icon)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Uploader);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(DatetimePicker);
Vue.use(Divider);
Vue.use(Toast);
Vue.use(Notify);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Empty)
Vue.use(NavBar);
Vue.use(Radio);
Vue.use(RadioGroup);